import PropTypes from "prop-types";
import CoreContainer from "../core-container";

const LeftTitleRightText = ({ data = {} }) => {
  const { title, rightText, moduleWidth, theme } = data;
  const isFullWidth = moduleWidth?.size === "fullWidth";
  return (
    <CoreContainer isFullWidth={isFullWidth} theme={theme?.value} mobilePadding={false}>
      <section className="mx-auto max-w-[316px] py-16 text-center md:max-w-[751px] md:py-20">
        <p className=" whitespace-pre-wrap pb-6 text-3xl font-normal lg:text-5xl">{title}</p>
        <p className="text-lg font-light md:text-lg">{rightText}</p>
      </section>
    </CoreContainer>
  );
};

LeftTitleRightText.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    rightText: PropTypes.string,
    theme: PropTypes.shape({
      value: PropTypes.oneOf(["light", "soft", "dark"]),
    }),
    moduleWidth: PropTypes.shape({
      size: PropTypes.string,
    }),
  }),
};

export default LeftTitleRightText;
